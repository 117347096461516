<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课件列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="课件名称" class="searchboxItem ci-full">
              <span class="itemLabel">课件名称:</span>
              <el-input v-model="kpointName" type="text" size="small" placeholder="请输入课件名称" clearable />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree ref="tree" @eventBtn="childBack" :ruleForm="ruleForm" size="small" @clearParams="clearParams" modal
                typeStu />
            </span>
            <div title="是否为公共课" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6.5rem">是否为公共课:</span>
              <el-select v-model="publicKpoint" placeholder="请选择是否为公共课" size="small" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="资源提供者" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">资源提供者:</span>
              <el-select size="small" v-model="compId" remote :remote-method="getCompanyList" filterable clearable
                placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
            <!-- <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button> -->
          </div>
          <div class="searchbox" style="margin-bottom:5px">
            <div title="市场状态" class="searchboxItem ci-full">
              <span class="itemLabel">市场状态:</span>
              <el-select v-model="saleState" placeholder="请选择市场状态" size="small" clearable>
                <el-option v-for="item in salestatelist" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="课件Id" class="searchboxItem ci-full">
              <span class="itemLabel">课件Id:</span>
              <el-input v-model="kpointId" type="text" size="small" placeholder="请输入课件Id" clearable />
            </div>
            <div title="是否启用试看" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6.5rem">是否启用试看:</span>
              <el-select v-model="kpointTrySee" placeholder="请选择是否启用试看" size="small" clearable>
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="数据来源" class="searchboxItem ci-full">
              <span class="itemLabel">数据来源:</span>
              <el-select v-model="kpointSourceC" placeholder="请选择数据来源" size="small" clearable>
                <el-option v-for="item in kpointSourceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin:0 10px" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed width="100" />
              <el-table-column label="课件Id" align="left" prop="kpointId" show-overflow-tooltip width="100" fixed />
              <el-table-column label="课件名称" align="left" prop="kpointName" show-overflow-tooltip width="280" fixed />
              <el-table-column label="培训类型" align="left" show-overflow-tooltip width="120" prop="trainTypeNamePath">
                <template slot-scope="scope">{{ scope.row.trainTypeNamePath || '--' }}</template>
              </el-table-column>
              <el-table-column label="岗位类型" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.postName || '--' }}</template>
              </el-table-column>
              <el-table-column label="行业类型" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.industryNamePath || '--' }}</template>
              </el-table-column>
              <el-table-column label="职业/工种" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.occupationNamePath || '--' }}</template>
              </el-table-column>
              <el-table-column label="培训等级" align="left" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.trainLevelName || '--' }}</template>
              </el-table-column>
              <el-table-column label="学时" align="right" prop="kpointLessonNum" show-overflow-tooltip />
              <el-table-column label="视频时长" align="right" prop="kpointDuration" show-overflow-tooltip>
                <template slot-scope="scope">{{ getTime(scope.row.kpointDuration) }}</template>
              </el-table-column>
              <el-table-column label="资源提供者" align="left" prop="compName" show-overflow-tooltip width="260" />
              <el-table-column label="数据来源" align="center" show-overflow-tooltip prop="kpointSource" minWidth="120">
                <template slot-scope="scope">{{ $setDictionary("KPOINT_SOURCE", scope.row.kpointSource) }}</template>
              </el-table-column>
              <el-table-column label="公共课" align="center" show-overflow-tooltip prop="publicKpoint" minWidth="120"
                fixed="right">
                <template slot-scope="scope">{{ scope.row.publicKpoint == true ? '是' : '否' }}</template>
              </el-table-column>
              <el-table-column label="启用试看" align="center" show-overflow-tooltip prop="publicKpoint" minWidth="120"
                fixed="right">
                <template slot-scope="scope">{{ scope.row.trySee == true ? '是' : '否' }}</template>
              </el-table-column>
              <el-table-column label="市场状态" align="center" show-overflow-tooltip prop="salesState" minWidth="120"
                fixed="right">
                <template slot-scope="scope">{{ $setDictionary("SELLSTATE", scope.row.salesState) }}</template>
              </el-table-column>
              <el-table-column label="操作" fixed="right" align="center" width="80px">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="EditKonipt(scope.row.kpointId)">编辑</el-button>
                  <el-button type="text" size="mini" style="padding:0px 5px" @click="showVideo(scope.row)">预览</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog v-if="dialogVisible1" :title="videotitle" :visible.sync="dialogVisible1" width="60%"
      :before-close="handleClose">
      <player_1 :videoId="videoId" :videoTime="videoTime" :kpointSource="kpointSource" ref="player" />
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
import player_1 from "@/components/player";
import { distorySwigger } from "../../../utils/swigger";
export default {
  name: "koniptList",
  components: {
    Empty,
    PageNum,
    player_1,
    tree
  },
  mixins: [List],
  data() {
    return {
      kpointName: "",// 课件名称
      kpointId: '', //课件Id
      saleState: "20", //市场状态
      params: {},
      ruleForm: {
        Trainingtype: ""
      },
      // 市场状态
      salestatelist: [],
      CompanyList: [],
      // 数据来源下拉数据
      kpointSourceList:[],
      compId: "", //资源提供者
      publicKpoint: "", //是否公共课
      kpointTrySee: "", //是否启用试看
      kpointSourceC:"", //数据来源
      options: [
        {
          value: "",
          label: "全部"
        },
        {
          value: true,
          label: "是"
        },
        {
          value: false,
          label: "否"
        }
      ],
      // 预览
      videoId: "",
      videoType: null, // true-可以看；false-试看
      videoTime: "",
      videotitle: "",
      kpointSource: "",
      dialogVisible1: false,
    };
  },
  created() {
    this.getcompanytypeList();
  },
  computed: {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    // 预览
    showVideo(data) {
      this.$post("/biz/courseware/auth", { videoId: data.kpointVideoId })
        .then((result) => {
          this.videoId = data.kpointVideoId;
          this.videoType = result.data.trySee;
          this.videoTime = result.data.duration;
          this.videotitle = data.kpointName;
          this.kpointSource = data.kpointSource;
          this.dialogVisible1 = true;
        });
    },
    // 关闭销毁
    handleClose() {
      this.$refs.player.dd();
      distorySwigger();
      this.dialogVisible1 = false
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.kpointName) {
        params.kpointName = this.kpointName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.kpointId) {
        params.kpointId = this.kpointId;
      }
      //去掉判断，防止为false的时候不赋值
      params.publicKpoint = this.publicKpoint;
      params.trySee = this.kpointTrySee;
      if (this.saleState) {
        params.salesState = this.saleState;
      }
      if (this.kpointSourceC) {
        params.kpointSource = this.kpointSourceC;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      if (this.params.levelFormId) {
        params.trainLevelId = this.params.levelFormId;
      }
      if (this.params.occFormId) {
        params.occupationId = this.params.occFormId;
      }
      if (this.params.postFormId) {
        params.postId = this.params.postFormId;
      }
      if (this.params.industryFormId) {
        params.industryId = this.params.industryFormId;
      }
      this.doFetch({
        url: "/run/courseware/listPage",
        params,
        pageNum
      });
    },
    //编辑
    EditKonipt(kpointId) {
      this.$router.push({
        path: "/web/operate/koniptDetails",
        query: {
          kpointId
        }
      });
    },
    // 资源提供者1
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取字典
    getcompanytypeList() {
      const sellstatelist = this.$setDictionary("SELLSTATE", "list");
      const list = [{ value: "", label: "全部" }];
      for (const key in sellstatelist) {
        list.push({
          value: key,
          label: sellstatelist[key]
        });
      }
      this.salestatelist = list;
      // 数据来源
      const arr2 = this.$setDictionary("KPOINT_SOURCE", "list");
      for (const key in arr2) {
        this.kpointSourceList.push({
          value: key,
          label: arr2[key]
        });
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
        (m != "00" ? m + "分" : "") +
        (s != "00" ? s + "秒" : "") || ""
      );
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less" scoped></style>
